import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Agradecimento from "@src/views/agradecimento";

import Main from '@src/views/main';

export default function Rotas() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route exact path="/obrigado" element={<Agradecimento/>} />
      </Routes>
    </Router>
  );
}