import React, { createContext, useContext, useEffect, useState } from 'react';

const ContextoIdioma = createContext();

const ProvedorIdioma = ({ children }) => {
    const [idioma, setIdioma] = useState('pt-BR');

    useEffect(()=> {
        localStorage.setItem('lang','pt-BR');
    },[])

    return (
        <ContextoIdioma.Provider
            value={{
                idioma,
                setIdioma
            }}
        >
            {children}
        </ContextoIdioma.Provider>
    )
}

const useIdioma = () => useContext(ContextoIdioma);

export { ProvedorIdioma, useIdioma }