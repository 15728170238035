import React, { useEffect, useState } from 'react';
import './styles.scss';

import Logo from '@src/assets/logo.png';
import FlagBrazil from '@src/assets/bandeira_brasil.png';
import FlagEUA from '@src/assets/bandeira_EUA.png';
import FlagSpain from '@src/assets/bandeira_espanha.png';

import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useIdioma } from '@src/hooks/useLang';
import { faBars, faTimes, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useScrollSize from '@src/hooks/useScroll';

const Header = () => {
    const [menuAtivo, setMenuAtivo] = useState(false);
    const [menuIdioma, setMenuIdioma] = useState(false);

    const { t, i18n } = useTranslation();
    const { idioma, setIdioma } = useIdioma();
    const [scrollX, scrollY] = useScrollSize();

    useEffect(() => {
        i18n.changeLanguage(idioma)
    },[idioma])
    
    const opcoes = [
        {
            "lang":"pt-BR",
            "img":FlagBrazil
        },
        {
            "lang":"en-US",
            "img":FlagEUA
        },
        {
            "lang":"es",
            "img":FlagSpain
        }
        
    ]

    const abrirMenu = () => {
        setMenuAtivo(true)
    }

    const fecharMenu = () => {
        setMenuAtivo(false)
    }


    const lidarComMenuIdioma = () => {
        setMenuIdioma(!menuIdioma)
        
    }

    const lidaComOpcoes = (item) => {
        setIdioma(item)
        localStorage.setItem("lang",item)

        setMenuIdioma(false)

    }


    const lidarComListaDeIdioma = () => {
        return menuIdioma
            ? <ul id='list-lang'>
                {
                    opcoes.map((item, index)=> {
                        return item.lang === idioma
                            ? null
                            : <li className='opcoes' onClick={()=> lidaComOpcoes(item.lang)} key={index}>
                                <img src={item.img}/>
                              </li>
                    })
                }
              </ul>
            : null
    }

    return (
        <header 
            style={{
                background: scrollY > 50 || menuAtivo ? '#012E40' : null , 
                transition:'0.7s',
            }}    
        >
            <figure>
                <img src={Logo} alt="Identidade Visual" />
            </figure>
            {
                menuAtivo
                    ? <button onClick={fecharMenu} id="btnFechar">
                       <FontAwesomeIcon icon={faTimes} color="#FFF" size="2x"/>
                      </button>
                    : <button id="btnAbrir" onClick={abrirMenu}>
                        <FontAwesomeIcon icon={faBars} color="#FFF" size="2x"/>
                      </button>
            }
            <nav className='menu' style={
                menuAtivo 
                    ? { 
                        display:'initial' 
                      }
                    : { 
                        display:'none' 
                      }
            }>
                <ul>
                    <Link to="home" spy={true} smooth={true} offset={50} duration={500}><li>{t("menuPrincipal.home")}</li></Link>
                    <Link to="sobre" spy={true} smooth={true} offset={-200} duration={500}><li>{t("menuPrincipal.sobreNos")}</li></Link>
                    <Link to="servicos" spy={true} smooth={true} offset={-250} duration={500}><li>{t("menuPrincipal.servicos")}</li></Link>
                    <Link to="contatos" spy={true} smooth={true} offset={50} duration={500}><li>{t("menuPrincipal.contato")}</li></Link>
                    <li >
                        <div className='seletor'>
                            <div id="selecionarIdioma" style={{
                                marginTop:menuIdioma ? '67px' : '0'
                            }}
                            onClick={lidarComMenuIdioma}
                            >
                                {
                                    idioma === 'pt-BR'
                                        ? <img src={FlagBrazil} />
                                        : idioma === 'en-US'
                                        ? <img src={FlagEUA} />
                                        : <img src={FlagSpain} />
                                }
                                <FontAwesomeIcon icon={faAngleDown} id="iconeSetaParaBaixo"/>
                            </div>
                            {lidarComListaDeIdioma()}
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export { Header };