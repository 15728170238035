import React, { useEffect } from 'react';
import './styles.scss';

import { CardInfo } from './CardInfo';
import { useTranslation } from 'react-i18next';

import InfoGrafico from './InfoGrafico';

const Servicos = () => {

    const { t } = useTranslation()
   
    return (
        <section id="servicos" className='secao-servicos' >
            <div id="area-cabecalho">
                <h1>{t('secaoServico.titulo')}</h1>
                <span>{t('secaoServico.descricao')}</span>
            </div>
            <div id="area-servicos">
                <CardInfo />
                <InfoGrafico/>
            </div>
        </section>
    )
}

export default Servicos;