import React from 'react';
import './styles.scss';

const Botao = ( { titulo, onClick } ) => {
    
    return (
        <button 
            onClick={onClick}
            id='containerBotao' 
        >
            {titulo}
        </button>
    )
}

export { Botao };