import React, { useState } from 'react';
import './styles.scss';

import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/bootstrap.css'

import iconeWhats from '@src/assets/iconWhats.png';
import iconeEMail from '@src/assets/iconMail.png';
import iconeTelefone from '@src/assets/iconPhone.png';
import iconeLocalizacao from '@src/assets/iconLocation.png';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';

import axios from 'axios';

import { sha256 } from 'js-sha256';
import { useTranslation } from 'react-i18next';
import { Botao } from '@src/componentes/Botao';

const Contatos = () => {
    const { t } = useTranslation();

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [carregando, setCarregando] = useState(false);

    const verificarInputs = () => {
        if(nome === '' || email === '' || mensagem === '') {
            return false;
        }

        return true;
    }

    const enviarEmail = async (e) => {
        try {
            e.preventDefault();

            const camposObrigatoriosPreenchidos = verificarInputs();
    
            if(!camposObrigatoriosPreenchidos){
                return;
            }
    
            const tkn = process.env.REACT_APP_SAGRES+email;
    
            const hash = sha256(tkn)

            const dados = {
                dsc_nome:nome,
                dsc_email:email,
                dsc_telefone:telefone,
                dsc_texto:mensagem,
                dsc_token:hash
            }
    
            setCarregando(true);
            const resposta = await axios.post("https://backendservices.quattror.com.br/api/sagres-email/contato",dados);
    
            return resposta.data.sucesso === true
                ? respostaSucesso()
                : respostaFalha()  

        } catch(err) {
            respostaFalha()
        }
        finally {
            setCarregando(false);
        }
    }

    const respostaSucesso = () => {
          Swal.fire({
            icon: 'success',
            title: 'E-mail enviado',
            text: 'Parabéns seu e-mail foi enviado com sucesso',
          })
    }

    const respostaFalha = () => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Houve uma falha ao enviar o e-mail, tente novamente mais tarde.',
          })
    }

    const renderizarConteudoBotao = () => {
        return carregando
            ? <ReactLoading  type="spin" height={'30px'} width={'30px'} />
            : t('formulario.enviar')
    }
 
    return (
        <div id="contatos" className='area-contato'>
            <form id="form-contato" method="POST" > 
                <input
                    id="nameInput"
                    type="text"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    placeholder={t('formulario.inputNome')}
                    required
                />
                <input
                    id="emailInput"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t('formulario.inputEmail')}
                    required
                />
                
                <div id="areaTelefone">
                    <PhoneInput
                        id="phoneInput"
                        name="telefone"
                        country={'br'}
                        value={telefone}
                        onChange={(tel)=> setTelefone(tel)}
                        inputStyle={{ 
                            width:'100%',
                            border:'none',
                            borderBottom:'1px solid #FFF', 
                            outline: 'none',
                            color: '#FFF',
                            background:'transparent',
                        }}
                        dropdownStyle={{
                            color:'#000',
                        }}
                        containerStyle={{
                            outline:'none',
                        }}
                        searchStyle={{
                            backgroundColor:'red'
                        }}
                    />
                    <input 
                        hidden
                        id="phoneInput"
                        name="telefone"
                        value={telefone}
                        onChange={(text)=> setTelefone(text)}
                    />
                </div>
                
                <br />
                <textarea
                    rows="10"
                    cols="40"
                    placeholder={t('formulario.inputMensagem')}
                    name="mensagem"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    required
                />
                <input type="hidden" name="_captcha" value="false"/>
                <input type="hidden" name="_next"  value="http://localhost:3000/obrigado"/>
                <Botao 
                    titulo={renderizarConteudoBotao()} 
                    type="submit" onClick={enviarEmail} 
                />
            </form>

            <div id="info-links">
                <h1 id="titulo-contato">{t('formulario.titulo')}</h1>
                <div className='links'>
                    <img src={iconeWhats} />
                    <span> 55+ (27) 3078-9380</span>
                </div>
                <div className='links'>
                    <img src={iconeEMail} />
                    <span>sagres@sagresglobal.com</span>
                </div>
                <div className='links'>
                    <img src={iconeTelefone} />
                    <span>55+ (27) 3078-9380</span>
                </div>
                <div className='links'>
                    <img src={iconeLocalizacao} />
                    <span>
                        R. João Pessoa de Mattos, 505 - Praia da Costa, Vila Velha - ES, 29101-115
                    </span>
                </div>
                {/* <div className='links'>
                    <img src={iconInstagram} />
                    <img src={iconLinkedIn} />
                </div> */}
            </div>
        </div>
    )
}

export default Contatos;