const pt = {
    translation:{
        menuPrincipal:{
            home:'Home',
            sobreNos:"Sobre",
            servicos:"Serviços",
            contato:"Contato"
        },
        banner:{
            conectar:'Conectar'
        },
        secaoSobre:{
            titulo:"SOMOS A SAGRES!",
            primeiroParagrafo:"Inspirados pela Escola de Sagres, que propunha ensinar a navegar e abrir caminhos para o comércio, nós, da Sagres Assessoria Internacional, estamos focados em achar soluções para as empresas brasileiras.",
            segundoParagrafo:"Usamos a tecnologia a nosso favor, diminuindo as barreiras e os custos de quem deseja se internacionalizar de forma eficiente.",
            terceiroParagrafo:"Através da busca de fornecedores em todo o mundo, temos o serviço certo para encontrar os melhores produtos, sempre tendo em mente a necessidade do cliente e o cumprimento das legislações dos órgãos anuentes.",
            card:{
                primeiroCard:{
                    titulo:"NOSSA MISSÃO",
                    descricao:"Desenvolver a conexão entre fornecedores e compradores, superando as barreiras do comércio internacional."
                },
                segundoCard:{
                    titulo:"NOSSA VISÃO",
                    descricao:"Ser referência em gerar resultados a partir da busca de fornecedores e constante melhoria de produtos e processos."
                },
                terceiroCard:{
                    titulo:"NOSSOS VALORES",
                    descricao:"Transparência nas negociações, buscando sempre o melhor para nossos clientes e parceiros, unindo a tecnologia e inovação em prol de resultados."
                }
            }
        },
        secaoServico:{
            titulo:'SERVIÇOS',
            descricao:'Pensando em resolver seus problemas, conectamos fornecedores e compradores de maneira fácil e sem burocracias.',
            cardInformacao:{
                primeiroItem:"Básico",
                descricaoPrimeiroItem:"conectar aos compradores e fornecedores",
                segundoItem:"Intermediário",
                descricaoSegundoItem:"conectar fornecedores que estejam dentro das condições de comprador",
                terceiroItem:'Avançado',
                descricaoTerceiroItem:"desenvolvimento de estudo de órgãos anuentes e INPI e entrega de planilha de estimativa completa",
                quartoItem:"Desenvolvimento de estudo",
                descricaoQuartoItem:"de orgãos e INPI e entrega de planilha de estimativa completa",
                quintoItem:"Avaliação e intermediação",
                descricaoQuintoItem:"da negociação com os fornecedores",
                sextoItem:"Gerenciamento de amostras",
                setimoItem:"Intermediação de contratos",
                descricaoSetimoItem:"entre o cliente e o fornecedor",
                oitavoItem:"Acompanhamento do primeiro embarque"
            }
        },
        formulario:{
            inputNome:"Seu Nome",
            inputEmail:"Seu E-mail",
            inputMensagem:"Sua Mensagem...",
            titulo:"CONTATO",
            enviar:"Enviar"
        },
        swall:{
            titulo:'Obrigado',
            texto:'E-mail enviado'
        },
        agradecimento:{
            tituloBotao:'Voltar'
        } 
     }
}

export { pt };