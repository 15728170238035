const es = {
    translation:{
        menuPrincipal:{
            home:'Home',
            sobreNos:"Sobre Sagres",
            servicos:"Servicios",
            contato:"Contáctanos"
        },
        banner:{
            conectar:'Conéctate'
        },
        secaoSobre:{
            titulo:"SOMOS SAGRES!",
            primeiroParagrafo:"Inspirados por la Escuela de Sagres, que se proponía a enseñar la navegación y abrir caminos para el comercio, nosotros de Sagres Asesoramiento Internacional estamos enfocados en encontrar soluciones para las empresas brasileñas.",
            segundoParagrafo:"Empleamos la tecnología a nuestro favor, disminuyendo las barreras y costos de quién desea internacionalizarse de manera eficiente.",
            terceiroParagrafo:"A través de la búsqueda de proveedores en todo el mundo, tenemos el servicio adecuado para encontrar los mejores productos, siempre teniendo en cuenta la necesidad del cliente y el cumplimiento de las leyes de las autoridades licenciantes.",
            card:{
                primeiroCard:{
                    titulo:"NUESTRA MISIÓN",
                    descricao:"Desarrollar la conexión entre proveedores y compradores, superando las barreras del comercio internacional."
                },
                segundoCard:{
                    titulo:"NUESTRA VISIÓN",
                    descricao:"Ser la referencia en generar resultados a través de la búsqueda de proveedores y la constante mejoría de productos y procesos"
                },
                terceiroCard:{
                    titulo:"NUESTROS VALORES",
                    descricao:"Transparencia en las negociaciones, siempre buscando lo mejor para nuestros clientes y asociados, uniendo la tecnología y la innovación para alcanzar resultados."
                }
            }
        },
        secaoServico:{
            titulo:'SERVICIOS',
            descricao:'Pensando en resolver sus problemas, conectamos proveedores y compradores de manera fácil y sin burocracia.',
            cardInformacao:{
                primeiroItem:"Básico",
                descricaoPrimeiroItem:"conectar compradores y proveedores",
                segundoItem:"Intermediario",
                descricaoSegundoItem:"conectar proveedores que estén dentro de los requisitos del comprador",
                terceiroItem:'Avanzado',
                descricaoTerceiroItem:"desarrollo de estudio de autoridades licenciantes e INPI (Instituto Nacional de Propiedad Industrial), y entrega de plantilla de estimación de costes.",
                quartoItem:"Desarrollo de estudio",
                descricaoQuartoItem:"de autoridades licenciantes e INPI, y entrega de plantilla de estimación de costes",
                quintoItem:"Evaluación e intermediación ",
                descricaoQuintoItem:"de la negociación con los proveedores",
                sextoItem:"Manejo de muestras",
                setimoItem:"Intermediación de contratos",
                descricaoSetimoItem:"entre el cliente y el proveedor",
                oitavoItem:"Seguimiento del primer embarque"
            }
        },
        formulario:{
            inputNome:"Su Nombre",
            inputEmail:"Su Email",
            inputMensagem:"Su Mensaje...",
            titulo:"CONTÁCTANOS",
            enviar:"Envío"
        },
        swall:{
            titulo:'Gracias',
            texto:'Correo envío'
        },
        agradecimento:{
            tituloBotao:'Devolver'
        }  
     }
}

export { es };