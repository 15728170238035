import React, { useLayoutEffect, useState } from 'react';

function useDimensoes() {
  const [dimensoes, setDimesoes] = useState([0, 0]);

  useLayoutEffect(() => {
    function atualizarDimensoes() {
      setDimesoes([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', atualizarDimensoes);
    atualizarDimensoes();

    return () => window.removeEventListener('resize', atualizarDimensoes);
  }, []);

  return dimensoes;
}

export default useDimensoes;
