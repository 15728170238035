import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Botao } from '@src/componentes/Botao';

import './styles.scss';
import { t } from 'i18next';

const Agradecimento = () => {
    const navigation = useNavigate();
    const storage = localStorage.getItem('lang')

    const voltarParaHome = () => {
        navigation('/')
    }


    return (
        <div id='container'>
            {
                storage === "pt-BR" &&
                <>
                    <h1 id="title">E-MAIL ENVIADO</h1><br/>
                    <h1 id="title">OBRIGADO</h1>
                </>
            }
            {
                storage === "en-US" &&
                <>
                    <h1 id="title">EMAIL SENT</h1><br/>
                    <h1 id="title">THANK YOU</h1>
                </>
            }
            {
                storage === "es" &&
                <>
                    <h1 id="title">CORREO ENVIADO</h1><br/>
                    <h1 id="title">GRACIAS</h1>
                </>
            }
            <Botao titulo={t('agradecimento.tituloBotao')} onClick={voltarParaHome} id="btn-goBack"/>
        </div>
    )
}

export default Agradecimento;