import React from 'react';
import './styles.scss';

const Card = ( { titulo, descricao, imagem, altura } ) => {

     return (
         <div className='container-card'>
             <div>
                <img src={imagem} className="icon" style={{
                    height:altura ? altura : '100%'
                }}/>
                <span className='titulo-card'>
                    {titulo}
                </span><br/>
                <span className='descricao-card'>
                    {descricao}
                </span>   
            </div> 
         </div>
     )
}

export { Card };