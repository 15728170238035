import React from 'react';

import Lottie  from 'react-lottie';
import WorldIcon from '@src/assets/world.png';
import VisionIcon from '@src/assets/iconVision.png';
import ValuesIcon from '@src/assets/iconValues.png';

import useDimensoes from '@src/hooks/useDimensoes';
import animationData from '@src/animation/animation.json';

import './styles.scss';

import { Card } from '@src/componentes/Card';
import { useTranslation } from 'react-i18next';

const Sobre = () => {
    const { t, i18n } = useTranslation(); 
    const [width, height] = useDimensoes(); 

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const renderizarLottie = () => {
        return width < 768
            ?  <Lottie options={defaultOptions} width={340} height={340}/>
            :  <Lottie options={defaultOptions} width={500} height={500}/>
    }
    

    return (
        <section id='sobre' className='secao-sobre'>
            <div id="container-sobre">
                <div id="conteudo-esquerda">
                    <h1>{i18n.t("secaoSobre.titulo")}</h1>  
                    <span>
                        {t('secaoSobre.primeiroParagrafo')} <br/>
                        {t('secaoSobre.segundoParagrafo')}<br/>
                        {t("secaoSobre.terceiroParagrafo")}
                    </span>
                </div>
                
                <div id="conteudo-direita" >
                 {renderizarLottie()}
                </div>
            </div>
            <div id='container-card'>
                <Card 
                    titulo={t('secaoSobre.card.primeiroCard.titulo')} 
                    descricao={t('secaoSobre.card.primeiroCard.descricao')}
                    imagem={WorldIcon}
                />
                <Card 
                    titulo={t('secaoSobre.card.segundoCard.titulo')} 
                    descricao={t('secaoSobre.card.segundoCard.descricao')}
                    imagem={VisionIcon}
                />
                <Card 
                    titulo={t('secaoSobre.card.terceiroCard.titulo')} 
                    descricao={t('secaoSobre.card.terceiroCard.descricao')}
                    imagem={ValuesIcon}
                />
            </div>
        </section>
    )
}

export default Sobre;