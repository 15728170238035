
const en = {
    translation:{
        menuPrincipal:{
            home:'Home',
            sobreNos:"About us",
            servicos:"Services",
            contato:"Contact us"
        },
        banner:{
            conectar:'Connect'
        },
        secaoSobre:{
                titulo:"WE’RE SAGRES!",
                primeiroParagrafo:"Inspired by the School of Sagres, which proposed to teach how to navigate and open routes for trading, Sagres International Consulting is focused on finding solutions to Brazilian companies.",
                segundoParagrafo:"We use technology in our favor, reducing barriers and costs for the ones who want to internationalize in an efficient way.",
                terceiroParagrafo:"By seeking for suppliers worldwide, we have the perfect service to find the best products, always bearing in mind the needs of the client and the compliance with licensing authorities’ regulations.",
                card:{
                    primeiroCard:{
                        titulo:"OUR MISSION",
                        descricao:"To develop the connection between suppliers and buyers, overcoming barriers in the international trade."
                    },
                    segundoCard:{
                        titulo:"OUR VISION",
                        descricao:"Be the reference in creating results through the search for suppliers and the continuous improvement of products and processes."
                    },
                    terceiroCard:{
                        titulo:"OUR VALUES",
                        descricao:"Transparency in the negotiations, always seeking for the best to our clients and partners, combining technology and innovation to achieve results."
                    }
                }
            
        },
            secaoServico:{
                titulo:'SERVICES',
                descricao:'Focused on solving your problems, we easily connect suppliers and buyers without bureaucracies.',
                cardInformacao:{
                    primeiroItem:"Basic",
                    descricaoPrimeiroItem:"connect buyers and suppliers",
                    segundoItem:"Intermediate",
                    descricaoSegundoItem:"connecting suppliers that fit the buyers’ requirements",
                    terceiroItem:'Advanced',
                    descricaoTerceiroItem:"developing a study of licensing authorities and INPI (National Institute of Industrial Property)",
                    quartoItem:"Development of a study",
                    descricaoQuartoItem:"on licensing authorities and INPI, and delivery of estimated costs spreadsheet",
                    quintoItem:"Assessment and intermediation",
                    descricaoQuintoItem:"of the negotiation with the supplier",
                    sextoItem:"Management of samples",
                    setimoItem:"Intermediation of contracts",
                    descricaoSetimoItem:"between the client and the supplier",
                    oitavoItem:"Follow-up on the first shipment"
                }
            },
            formulario:{
                inputNome:"Your Name",
                inputEmail:"Your E-mail",
                inputMensagem:"Your Message...",
                titulo:"CONTACT US",
                enviar:"Send"
            },
            swall:{
                titulo:'Thank you',
                texto:'Email sent'
            },
            agradecimento:{
                tituloBotao:'Go back'
            } 
        }
}

export { en }