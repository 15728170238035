import React from 'react';
import './styles.scss';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-scroll';

const Divider = () => {

    return (
        <div className='dividerContainer'>
            <Link to="sobre" spy={true} smooth={true} offset={-200} duration={500}>
                <FontAwesomeIcon icon={faAngleDown} color="#F2A057" className="iconeSetaParaBaixo" size="3x"/>
            </Link>
        </div>
    )
}

export { Divider };