import React from 'react';
import './styles.scss';

import { Botao } from '@src/componentes/Botao';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useIdioma } from '@src/hooks/useLang';

const Home  = () => {
    const { t } = useTranslation();
    const { idioma } = useIdioma();
    
    return (
       <div id="home" className="banner">
           <div id='content'>
               <div id="area-titulo">
                   {
                       idioma === 'pt-BR' &&
                        <>
                            <div>
                                <h1 id='titulo'>COMÉRCIO <span className='titulo-customizado'> INTERNACIONAL</span></h1>
                            </div>
                            <div>
                                <h1 className='titulo-customizado'> TRANSPARENTE <span id='titulo'> E SEM BARREIRAS</span></h1>
                            </div>
                        </>
                   }
                   {
                       idioma === 'en-US' &&
                       <>
                           <div>
                               <h1 id='titulo'>OPEN AND <span className='titulo-customizado'> BARRIER-FREE</span></h1>
                           </div>
                           <div>
                               <h1 className='titulo-customizado'> INTERNATIONAL <span id='titulo'> TRADE</span></h1>
                           </div>
                       </>
                   }
                   {
                       idioma === 'es' &&
                       <>
                           <div>
                                <h1 id='titulo'>COMERCIO <span className='titulo-customizado'> INTERNACIONAL</span></h1>
                            </div>
                            <div>
                                <h1 className='titulo-customizado'> TRANSPARENTE  <span id='titulo'> Y SIN BUROCRACIA</span></h1>
                            </div>
                       </>
                   }
                    <span id='subtitulo'>
                        {
                            idioma === 'pt-BR' &&
                                <>
                                   CONECTANDO FORNECEDORES E COMPRADORES <br/>
                                    DE MANEIRA FÁCIL E SEM BUROCRACIAS
                                </>
                        }
                        {
                            idioma === 'en-US' &&
                            <>
                                EASILY CONNECTING BUYERS <br/>
                                AND SUPPLIERS WITHOUT BUROCRACIES
                            </>
                        }
                        {
                            idioma !== 'en-US' && idioma !=='pt-BR' &&
                            <>
                                CONECTANDO PROVEEDORES Y COMPRADORES <br/>
                                FÁCILMENTE Y SIN BUROCRACIA
                            </>
                        }
                        
                    </span>
                </div>
                <Link to="contatos" spy={true} smooth={true} offset={50} duration={500} >
                    <Botao titulo={t('banner.conectar')} />
                </Link>
            </div>
       </div>
    )
}

export default Home;
