import './App.css';
import Rotas from './routes';

import { ProvedorIdioma} from './hooks/useLang';

function App() {
  return (
    <ProvedorIdioma>
      <Rotas/>
    </ProvedorIdioma>
  );
}

export default App;
