import React from 'react';

import InfoGraphicMobilePtBr from '@src/assets/infoGraphichBR.svg';
import InfoGraphicPtBr from '@src/assets/infoGraphic.svg';

import infoGraphicEnUs from '@src/assets/infoGraphicEnUs.svg';
import infoGraphicEnUsMobile from '@src/assets/infoGraphicEnUsMobile.svg';

import infoGraphicEs from '@src/assets/infoGraphicEs.svg';
import infoGraphicEsMobile from '@src/assets/infoGraphicEsMobile.svg';

import useDimensoes from '@src/hooks/useDimensoes';
import { useIdioma } from '@src/hooks/useLang';

const InfoGrafico = () => { 
    const [width, height] = useDimensoes(); 
    const { idioma } = useIdioma();

    const renderizarInfoGraficoEspanhol = () => {
        return width < 1400 
            ? <img src={infoGraphicEsMobile} />
            : <img src={infoGraphicEs} />
    }

    if(idioma === 'pt-BR') {
        return width < 1400 
            ? <img src={InfoGraphicMobilePtBr} />
            : <img src={InfoGraphicPtBr} />
    }

    if(idioma === 'en-US') {
        return width < 1400 
            ? <img src={infoGraphicEnUsMobile} />
            : <img src={infoGraphicEnUs} />
    }
    

    return renderizarInfoGraficoEspanhol()

}
export default InfoGrafico;

