import React, { useLayoutEffect, useState } from 'react';

function useScrollSize() {
  const [scroll, setScroll] = useState([0, 0]);

  useLayoutEffect(() => {
    function atualizarScroll() {
      setScroll([window.scrollX, window.scrollY]);
    }

    window.addEventListener('scroll', atualizarScroll);
    atualizarScroll();

    return () => window.removeEventListener('scroll', atualizarScroll);
  }, []);

  return scroll;
}

export default useScrollSize;