import React from 'react';

import { Header } from '@src/componentes/Header';
import { Divider } from '@src/componentes/Divider';

import Home from '@src/views/home';
import Sobre from '@src/views/sobre';
import Servicos from '@src/views/servicos';
import Contatos from '@src/views/contatos';

const Main  = () => {

    return (
       <>
            <Header/>
            <Home/>
            <Divider />
            <Sobre  />
            <Servicos />
            <Contatos/>
       </>
    )
}

export default Main;