import React from 'react';
import './styles.scss';

import { useTranslation } from 'react-i18next';

const CardInfo = () => {
    const { t } = useTranslation();

    return (
        <div id="container-card-info">
            <div id="wrapper-card-info">
                <h1>SEARCHING</h1>
                <ul>
                    <li><strong>{t('secaoServico.cardInformacao.primeiroItem')}</strong>: {t('secaoServico.cardInformacao.descricaoPrimeiroItem')};</li>
                    <li><strong>{t('secaoServico.cardInformacao.segundoItem')}</strong>: {t("secaoServico.cardInformacao.descricaoSegundoItem")};</li>
                    <li><strong>{t('secaoServico.cardInformacao.terceiroItem')}</strong>: {t('secaoServico.cardInformacao.descricaoTerceiroItem')};</li>
                </ul>
            </div>
            <div  id="wrapper-card-info">
                <h1>PROCUREMENT</h1>
                <ul>
                    <li><strong>{t('secaoServico.cardInformacao.quartoItem')}</strong> {t('secaoServico.cardInformacao.descricaoQuartoItem')};</li>
                    <li><strong>{t('secaoServico.cardInformacao.quintoItem')}</strong> {t('secaoServico.cardInformacao.descricaoQuintoItem')};</li>
                    <li><strong>{t('secaoServico.cardInformacao.sextoItem')}</strong></li>
                    <li><strong>{t('secaoServico.cardInformacao.setimoItem')}</strong> {t('secaoServico.cardInformacao.descricaoSetimoItem')};</li>
                    <li><strong>{t('secaoServico.cardInformacao.oitavoItem')}</strong></li>
                </ul>
            </div>
        </div>
    )
}

export { CardInfo };