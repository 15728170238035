import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { pt } from './locales/pt/pt-br.js';
import { en } from './locales/en/en-us.js';
import { es } from './locales/es/es.js';

import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    'pt-BR':pt,
    'en':en,
    'es':es
}

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng:'en',
        debug: false,
        interpolation:{
            escapeValue:false,
            formatSeparator:","
        },
        react: {
            wait: true
        }
    })

export default i18n;